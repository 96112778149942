import styled from "styled-components"

export const CareerStyled = styled.div`
  .subtitle-offer-mobile {
    font-size: 20px;
    font-weight: 500 !important;
    padding: 30px 0 20px;
  }
  .nav-career {
    padding: 0;
    margin: 0;
  }
  .heading {
    font-size: 20px;
    padding: 0;
    h1 {
      font-size: 37px;
      font-weight: 500;
    }
    p {
      font-weight: 300;
      margin: 0;
    }
    p.subtitle:nth-last-of-type(1) {
      padding-bottom: 30px;
    }
  }
  .green-line {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 70px;
      height: 1px;
      width: 100%;
      background-color: #94c835;
    }
    @media (max-width: 1200px) {
      &:after {
        display: none;
      }
    }
  }
  .card {
    border: none;
    border-radius: 0;
    background: transparent;
  }
  .card-header {
    user-select: none;
    cursor: pointer;
  }
  .card {
    border-bottom: 1px solid #dadada!important;
  }
  p.subtitle {
    font-weight: 500;
    padding: 20px 0 9px 0;
  }
  .accordion {
    background: transparent;
    padding-bottom: 30px;
    .card-header {
      background: transparent;
      border: none;
      padding: 0;
    }
    .card-body {
      font-size: 16px;
      padding: 0;
      p {
        margin: 0;
      }
      .subtitle-job {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 16px;
        padding: 30px 0 20px 0;
      }
      ul {
        list-style: none;
        li {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          font-weight: 300;
          position: relative;
          font-size: 20px;
          &:before {
            content: "\\2022";
            position: absolute;
            top: -2px;
            left: -8px;
            height: 22px;
            color: #94c835;
            font-weight: bold;
            width: 22px;
            margin-left: -1em;
            transform: scale(2);
          }
        }
        li:nth-last-of-type(1) {
          padding-bottom: 0;
        }
      }
      .button {
        padding: 47px 0 75px 0;
        max-width: 271px;
      }
    }
  }
  .text-field-gray {
    width: 100%;
    background-color: #f8f8f8;
    padding: 47px 0 52px 0;
    p {
      font-size: 20px;
      margin: 0;
      font-weight: 300;
      span {
        font-weight: 500;
      }
    }
  }
  .buttons {
    padding: 48px 0 80px 0;
    flex-direction: column;
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
    }
    a:first-child button {
      margin-bottom: 14px;
    }
  }
  @media (min-width: 1200px) {
    padding-top: 60px;
    .img-container {
      display: flex;
      align-items: flex-start;
      img {
        z-index: 1;
        height: 330px;
      }
    }
    .padding {
      padding: 100px 0 100px 0;
    }
    .heading {
      h1 {
        font-size: 48px;
        margin-bottom: 40px;
      }
      p.subtitle {
        padding: 0;
        margin-bottom: 25px;
      }
    }
    .set-padding {
      padding: 0 0 50px 0;
    }
    .bg-gray {
      background-color: #f8f8f8;
    }
    .subtitle-offer-desktop {
      font-size: 48px;
      font-weight: 500;
      margin-bottom: 18px;
    }
    .img-container {
      display: flex;
      justify-content: center;
    }
    .img-container-interview {
      justify-content: center;
      padding-top: 80px;
      img {
        height: 320px;
      }
    }
  }
  @media (min-width: 1440px) {
    .green-line {
      &:after {
        bottom: 70px;
      }
    }
  }
`
export const SingleAccordCareer = styled.div`
  background: transparent;
  color: #000;
  font-size: 20px;
  padding: 22px 0;
  //   ${props => (props.active ? "transparent" : "#646464")};
  position: relative;
  transition: 300ms;
  display: flex;
  align-items: center;
    font-weight: 500;
  img {
  margin-right: 12px;
  max-height: 26px;
  }
  &:after {
    transition: 300ms;
    position: absolute;
    right: 8px;
    top: 42%;
    transform: translateY(-50%);
    content: "";
    width: 12px;
    height: 12px;
    opacity: 0.2;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(${props => (props.active ? "135deg" : "45deg")});
  }
`
