import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { ModalContainer } from "./modalStyles"

function ModalComponent({
  children,
  careerView,
  contactConfirmModal,
  className = "modal-box",
  dialogClassName = "",
}) {
  const [show, setShow] = useState(false)

  const handleClose = () => {
    return setShow(false)
  }
  const handleSearchToggle = () => {
    typeof window !== "undefined" &&
      document.body.classList.toggle("modal-overflow")
  }
  const handleShow = () => {
    return setShow(true)
  }

  return (
    <ModalContainer>
      <div onClick={handleShow}>{children[0]}</div>
      <Modal
        dialogClassName={`${dialogClassName}
          ${contactConfirmModal ? "contact-confirm-modal" : ""}
          ${careerView ? "career-modal" : ""}
        `}
        className={className}
        show={show}
        onExit={handleSearchToggle}
        onEnter={handleSearchToggle}
        onHide={handleClose}
      >
        <div className="close-button-area" onClick={handleClose}>
          <div className="close-button-modal" />
        </div>
        <div>{children[1]}</div>
        {children[2] === undefined ? (
          ""
        ) : (
          <div className="search-options">
            <div className="button-item" onClick={handleClose}>
              {children[2]}
            </div>
            <div className="button-item">{children[3]}</div>
          </div>
        )}
      </Modal>
    </ModalContainer>
  )
}

export default ModalComponent
