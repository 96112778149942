import React from "react"
import Layout from "../../components/layout"

import Helmet from "react-helmet"
import CareerJobs from "../../components/career/careerJobs"

const CareerPage = () => (
  <>
    <Helmet>
      <title>WILLBUD | Kariera</title>
    </Helmet>
    <Layout>
      <CareerJobs />
    </Layout>
  </>
)

export default CareerPage
